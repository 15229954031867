<template>
  <v-app>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <div class="container_new">
      <!-- Banner Section -->
      <div class="banner">
        <div class="image-container">
          <img
            src="@/assets/thingsToDo/logowizfit.png"
            alt="Logo"
            class="responsive-image"
          />
        </div>

        <!-- Centered Text -->
        <div class="text-container">
          <h1 class="responsive-text">
            IT’S TIME TO TAKE<br />THE WIZFIT CHALLENGE
          </h1>
        </div>

        <!-- Arrow aligned to the right -->
        <div class="arrow-container">
          <img
            src="@/assets/thingsToDo/rightarrow.png"
            alt="Arrow"
            class="responsive-arrow"
          />
        </div>
      </div>

      <!-- Register Call to Action -->
      <div class="container">
        <div style="text-align: center; margin-top: 0rem" class="">
          <h4
            style="
              align-self: center;
              color: #38227a;
              font-size: clamp(1rem, 3vw, 3.5rem);
              font-family: Poppins;
              font-weight: 600;
              text-transform: uppercase;
              line-height: 40px;
              word-wrap: break-word;
            "
            class="text-primary"
          >
            Register for your school campaign today!
          </h4>
        </div>

        <!-- player sextion -->
        <div class="download">
          <div class="store-section">
            <img
              @click="openAppleStore"
              src="@/assets/thingsToDo/appstore.png"
            />
            <img @click="openGpayStore" src="@/assets/thingsToDo/gpay.png" />
          </div>
          <div class="player">
            <img
              src="@/assets/thingsToDo/player.png"
              alt="Player Image"
              style="
                max-width: 100vw;
                height: auto;
                max-height: 100vh;
                object-fit: contain;
              "
            />
          </div>
          <div class="mobile_img">
            <img src="@/assets/thingsToDo/downloadapp1.png" />
          </div>
          <div class="text-section">Don’t Wait!<br />download the app</div>
        </div>

        <!-- Cards Section for Benefits -->
        <div class="upper-section">
          <div class="title">
            <ul>
              <!-- Divider Line -->
              <li>
                <span
                  >Lifetime Access to an Extensive Workout Video Library</span
                >
              </li>

              <li>
                <span>Interactive Features to Boost Student Engagement</span>
              </li>
              <li><span>Easy and Quick Signup</span></li>
              <li><span>Simplified Sharing Tools</span></li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Footer Section with Text -->
      <div style="text-align: center">
        <p style="padding: 1rem">
          The Harlem Wizards are excited for you to download the WizFit
          Challenge app! It’s designed to offer the most convenient and enhanced
          features, optimized for your phone’s screen size. The app is
          completely free to download, with no in-app purchases. Alternatively,
          you can access the WizFit Challenge via your browser.
          <a
            href="/wizfitchallenge2"
            target="_blank"
            class="font-weight-bold text-decoration-none"
            >here</a
          >.
        </p>
      </div>
    </div>
  </v-app>
</template>
<style scoped>
/* ------------------------------
    Global Styles
------------------------------ */
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.text-orange {
  color: #ffa723;
}

.cursor-pointer {
  cursor: pointer;
}

/* ------------------------------
    Layout Styles (Container, Banner, etc.)
------------------------------ */
.container_new {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  align-items: flex-start;
  position: relative;
}

.banner {
  height: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  flex-wrap: nowrap;
  padding: 2px;
  background-color: #38227a;
  place-items: center;
}

.headline {
  color: #38227a;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
}

/* ------------------------------
    Image and Text Container Styles
------------------------------ */
.image-container {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  height: fit-content;
  object-fit: fill;
  transition: 2s;
  width: 100px;
}

.responsive-image {
  width: 200vw;
  max-width: 200px;
  height: auto;
  object-fit: contain;
  max-height: 150px;
}

.text-container {
  flex: 1 1 50%;
  text-align: center;
  margin: 0 20px;
}

.responsive-text {
  font-size: 3rem;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  color: orange;
  word-wrap: break-word;
  margin: 0;
}

.arrow-container {
  flex: 1 1 100px;
  display: flex;
  justify-content: flex-end;
  width: 10rem;
}

.responsive-arrow {
  height: 50px;
  max-height: 100px;
  width: 100%;
  max-width: 200px;
  height: auto;
}

/* ------------------------------
    Store Section Styles
------------------------------ */
.store-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  grid-row: span 1;
  grid-column: span 2;
}

.store-section img {
  max-width: 300px;
  max-height: 300px;
  width: auto;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s;
  object-fit: contain;
}

.store-section img:hover {
  transform: scale(1.05);
}

/* ------------------------------
    Text Section Styles
------------------------------ */
.text-section {
  grid-area: text;
  display: flex;
  text-align: center;
  place-self: center;
  grid-column: 2;
  grid-row: span 2;
  font-family: Alfa Slab One;
  font-weight: 400;
  font-size: clamp(1rem, 3vw, 3.5rem);
  text-transform: uppercase;
  word-wrap: break-word;
  z-index: 10;
  position: relative;
  color: #38227a;
  place-items: flex-end;
}

/* ------------------------------
    Player Section Styles
------------------------------ */
.player {
  grid-area: player;
  display: grid;
  justify-content: center;
  align-items: flex-start;
  grid-column: span 1;
  grid-column: 3;
  place-items: center;
  grid-row: span 3;
}

/* ------------------------------
    Download Section Styles
------------------------------ */
.download {
  display: grid;
  grid-template-areas:
    "store store player"
    "mobile_img text player"
    "mobile_img text player";
  gap: 20px;
  max-width: 100vw;
  margin: 0 auto;
  padding: 20px;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto auto auto;
}

.mobile_img {
  grid-area: mobile_img;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-row: span 2;
  grid-column: 1;
}

.mobile_img img {
  height: auto;
  width: 100%;
  object-fit: contain;
  max-height: fit-content;
}

/* ------------------------------
    Upper Section Styles
------------------------------ */
.upper-section {
  display: grid;
  width: 98%;
  max-width: 1900px;
  padding: 20px;
  margin: 1px;
  background-color: rgb(239, 235, 255);
  border: 2px solid rgb(56, 34, 122);
  border-radius: 18px;
  place-self: center;
  align-self: center;
}
.upper-section ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.upper-section ul li {
  flex: 0 0 48%;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.upper-section ul {
  column-count: 2;
  column-gap: 10px;
}

.upper-section .title {
  flex: 1 1 calc(50% - 10px);
  margin: 5px;
  min-width: 200px;
}

.upper-section .divider {
  border-top: 0.5px solid #38227a;
  /* Divider Line */
}

.title {
  display: grid;
  color: #38227a;
  font-family: "Oswald" !important;
  font-weight: 500;
  word-wrap: break-word;
  max-width: 100%;
  align-items: center;
}

hr.divider {
  border: 0.5px solid rgb(56, 34, 122);
}

/* ------------------------------
    Media Queries
------------------------------ */
@media (min-width: 1900px) {
  .container_new {
    padding: 0 0px; /* Add some horizontal padding, but avoid excess padding */
    max-width: 100vw;
    align-items: flex-start;
  }
}
@media (max-width: 768px) {
  .responsive-text {
    font-size: 1.5rem;
  }

  .responsive-image {
    max-width: 150px;
  }

  .responsive-arrow {
    height: 40px;
  }

  .upper-section {
    flex-direction: column;
  }
  .upper-section ul {
    display: grid;
  }

  .upper-section .title {
    flex: 1 1 100%;
    margin: 5px 0;
  }

  .download {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-template-areas:
      "store"
      "mobile_img"
      "text";
    grid-auto-flow: row;
    gap: 20px;
    grid-template-rows: 100px auto auto;
  }

  .player {
    display: none;
  }

  .mobile_img {
    grid-area: mobile_img;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-row: span 2;
    grid-column: 1;
    place-self: center;
  }

  .mobile_img img {
    height: max-content;
    width: 200px;
    place-self: center;
  }

  .upper-section ul {
    column-count: 1;
  }

  .text-section {
    display: flex;
    grid-row: auto;
    grid-template-columns: 0;
    place-items: flex-end;
  }

  .store-section {
    flex-direction: row;
    align-items: center;
    grid-template-columns: 0;
    place-self: center;
  }

  .store-section img {
    max-width: 150px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .responsive-text {
    font-size: 1.2rem;
  }

  .responsive-image {
    max-width: 120px;
  }

  .responsive-arrow {
    height: 30px;
  }
}

@media (max-width: 1250px) {
  .player {
    display: none;
  }
}

/* ------------------------------
    Specific Utility Styles
------------------------------ */
.text-orange {
  color: #ffa723;
}
</style>

<script>
import { API_ADMIN_GET_CAMPAIGN_SCHOOL_LIST } from "@/constants/APIUrls";
/*import {
  CAMPAIGN_ID,
} from "@/constants/APIKeys";
import { ROUTER_URL } from "../../../constants/urls";**/
import Helper from "@/helper";
import Axios from "@/api/BaseAxios";
export default {
  name: "CampaignManagement",
  // components:{ VideoSection: () =>
  //     import("@/components/PublicDonationPages/VideoSection/VideoSectionWizfit.vue"), },
  data() {
    return {
      loading: false,
      items: [],
      schoolList: [],
      adminAccess: localStorage.getItem("adminAccess"),
      search: "",
      showAllItems: false,
      noCampaignMessage: false,
    };
  },
  computed: {
    filteredSchoolList() {
      if (this.search.length > 0) {
        return this.schoolList.filter((item) =>
          `${item.city_name} ${item.state_name} ${item.school_name}`
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
      }
      return this.schoolList;
    },
    visibleItems() {
      if (this.showAllItems) {
        return this.filteredSchoolList;
      }
      return this.filteredSchoolList.slice(0, 2);
    },
  },
  watch: {
    search(newVal) {
      if (newVal.trim() === "") {
        // If input is cleared, load the full list
        this.getCampaignManagementData();
      } else if (newVal.trim().length >= 3) {
        // If 3 or more characters, fetch filtered list
        this.getCampaignManagementData(newVal);
      }
    },
  },
  methods: {
    seeMore() {
      // Increase the visible rows by 1 on each click
      this.visibleRowCount += 1;
    },
    getCampaignManagementData() {
      const self = this;
      self.loading = true;
      this.showAllItems = false;
      const successHandler = (res) => {
        self.schoolList = res.data.school_list;
        self.loading = false;
      };
      const failureHandler = () => {
        self.loading = false;
      };
      let formData = {};
      formData["search"] = this.search;
      Axios.request_GET(
        API_ADMIN_GET_CAMPAIGN_SCHOOL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    viewPublicDonationPageHandler(item) {
      const { sub_domain } = item;
      Helper.openPublicDonationPage(sub_domain);
    },
    reload() {
      location.reload();
    },
    openAppleStore() {
      window.open(
        "https://apps.apple.com/us/app/harlem-wizards/id6590627343",
        "_blank"
      );
    },
    openGpayStore() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.inmotion.harlemwizard&pli=1",
        "_blank"
      );
    },
  },
  mounted() {
    this.getCampaignManagementData();
  },
};
</script>
