<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <v-card flat class="card-datatable">
      <v-card-text>
        <v-row v-if="$vuetify.breakpoint.xsOnly">
          <v-col cols="6" md="3" class="pl-3" style="margin-left: 3%">
            <v-autocomplete
              label="Filter By Year"
              outlined
              dense
              v-model="yearFilter"
              :items="yearList"
              class="formFields"
              color="#7253CF"
              :disabled="loading"
              @change="getCampaignManagementData()"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.smAndUp">
          <v-col cols="6" md="3" class="pl-3">
            <v-autocomplete
              label="Filter By Year"
              outlined
              dense
              v-model="yearFilter"
              :items="yearList"
              class="formFields"
              color="#7253CF"
              :disabled="loading"
              @change="getCampaignManagementData()"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              outlined
              dense
              v-model="campaignDataTable[$API_KEYS.SEARCH]"
              label="Search by District, School or Campaign Status"
              append-icon="mdi-magnify"
              type="text"
              color="#38227A"
              class="search-input"
              height="43px"
            >
            </v-text-field>
          </v-col>

          <v-spacer></v-spacer>
          <v-col
            class="d-flex d-sm-none"
            cols="1"
            v-if="
              this.adminAccess === 'Super Admin' && $vuetify.breakpoint.xsOnly
            "
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
                  >mdi-menu</v-icon
                >
              </template>
              <v-list>
                <v-list-item-group active-class="active-class">
                  <v-list-item @click="newCampaign">
                    <v-list-item-title> Start New Campaign </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
          <v-col
            cols="3"
            md="2"
            class="text-right"
            v-if="
              this.adminAccess == 'Sales Representative' ||
              this.adminAccess == 'Event Support Staff'
            "
          >
            <v-btn
              color="#38227A"
              dark
              @click="ShowAll()"
              class="text-capitalize px-5"
              height="45px"
              style="border-radius: 10px; position: unset"
              v-if="!currentState"
            >
              Show All
            </v-btn>
            <v-btn
              color="#38227A"
              dark
              @click="myEvents()"
              class="text-capitalize px-5"
              height="45px"
              style="border-radius: 10px; position: unset"
              v-if="currentState"
            >
              My events
            </v-btn>
          </v-col>
          <v-col
            class="text-right"
            cols="3"
            v-if="
              this.adminAccess === 'Super Admin' && $vuetify.breakpoint.smAndUp
            "
          >
            <v-btn
              color="#38227A"
              dark
              @click="newCampaign"
              class="text-capitalize px-5"
              height="45px"
              style="border-radius: 10px; position: unset"
            >
              Start New Campaign
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="table-div" v-if="this.adminAccess === 'game'">
      <v-data-table
        hide-default-footer
        fixed-header
        :headers="campaignDataTable1.headers"
        :items="campaignDataTable.items"
        :loading="campaignDataTable.loading"
          campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        
        :server-items-length="
          campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :options.sync="dataOptions"
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint
      >
        <template v-slot:item="row">
          <tr class="text-flex" style="font-size: 14px">
            <td class="text-center row-item">
              <div>
                <v-chip
                  small
                  :class="`${row.item.campaign_status.toUpperCase()}`"
                >
                  {{ row.item.campaign_status.toUpperCase() }}
                </v-chip>
              </div>
              <div style="display: inline; font-weight: 600">
                ({{ row.item.start_date }} to {{ row.item.end_date }})
              </div>
              <div style="font-size: 14px; font-weight: 600">
                <p style="color: rgba(29, 29, 29, 0.6)">
                  {{ row.item.campaign_id }}
                </p>
              </div>
            </td>
            <td class="text-center row-item">
              <div>{{ row.item.city_name }},{{ row.item.state_name }}</div>
              <div>{{ row.item.district_name }}</div>
              <div>{{ row.item.school_name }}</div>
            </td>
            <td class="text-center row-item">
              <v-btn
                color="#38227A"
                width="30%"
                dark
                class="text-capitalize px-7 mr-5"
                @click="viewCampaignHandler(row.item)"
                height="45px"
                style="border-radius: 10px; margin-top: -17px; font-size: 15px"
                v-if="row.item.progress == 'complete'"
              >
                <span>Go To Dashboard</span>
              </v-btn>
              <v-btn
                color="#38227A"
                width="30%"
                dark
                class="text-capitalize px-7 mr-5"
                @click="viewCampaignHandlerHost(row.item)"
                height="45px"
                style="border-radius: 10px; margin-top: -17px; font-size: 15px"
                v-else
              >
                <span>Go To Dashboard</span>
              </v-btn>
            </td>
          </tr>
        </template>
        <template
          v-slot:footer
          v-if="campaignDataTable.items[0] && $vuetify.breakpoint.smAndUp"
        >
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-6 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="justify-center justify-md-start d-flex px-5 tableHeader-text"
                >
                  <div class="alignSelf-center mr-3">Show</div>
                  <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                    <v-select
                      class=""
                      :items="itemsPerPage"
                      hide-details
                      v-model="
                        campaignDataTable[$API_KEYS.PAGE_INFO][
                          $API_KEYS.ITEMS_PER_PAGE
                        ]
                      "
                      dense
                    ></v-select>
                  </div>
                  <div class="alignSelf-center">
                    {{
                      campaignDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    }}
                    of
                    {{
                      campaignDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    }}
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="4" md="4">
                <div>
                  <v-pagination
                    v-model="
                      campaignDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    "
                    :length="
                      campaignDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    "
                    :total-visible="5"
                    color="#D30024"
                    class="pagination"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="table-div" v-else>
      <v-data-table
        hide-default-footer
        fixed-header
        :headers="campaignDataTable.headers"
        :items="campaignDataTable.items"
        :loading="campaignDataTable.loading"
        :items-per-page="
          campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :server-items-length="
          campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :options.sync="dataOptions"
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint
      >
        <template v-slot:item="row">
          <tr class="text-flex" style="font-size: 14px">
            <td class="text-center row-item">
              <!--  <div style="display: inline; font-weight: 600">
                ({{ row.item.start_date_str }} to {{ row.item.end_date_str }})
              </div>
              <div style="font-size: 14px; font-weight: 600">
                <p style="color: rgba(29, 29, 29, 0.6)">
                  {{ row.item.campaign_id }}
                </p>
              </div>-->
              <div
                style="
                  font-weight: 600;
                  margin-left: 35px;
                  text-align-last: start;
                "
              >
                <b>{{ row.item.kick_off_date_str_2 }}</b> - &nbsp;<span
                  style="font-weight: 400"
                  >Kick Off</span
                >
                <br />
                <b>{{ row.item.gold_ball_giveaway_date_str_2 }}</b> -
                <span style="font-weight: 400">Platinum Ball Giveaway</span>
                <br />
                <b>{{ row.item.graduation_celebration_date_str_2 }}</b> -
                <span style="font-weight: 400">Graduation</span>
                <br />
                <b>{{ row.item.end_date_str_2 }}</b> -
                <span style="font-weight: 400">Donations Close</span>
                <br />
                <v-chip
                  small
                  :class="`${row.item.campaign_status.toUpperCase()}`"
                >
                  {{ row.item.campaign_status.toUpperCase() }}
                </v-chip>
              </div>
              <v-col cols="12">
                <!-- <event-timeline></event-timeline>-->
              </v-col>
            </td>
            <td class="text-center row-item">
              <div>{{ row.item.city_name }},{{ row.item.state_name }}</div>
              <div>{{ row.item.district_name }}</div>
              <div>{{ row.item.school_name }}</div>
            </td>
            <td class="text-center row-item" style="font-size: 14px">
              {{
                row.item.campaign_type.charAt(0).toUpperCase() +
                row.item.campaign_type.slice(1)
              }}
            </td>

            <td class="text-center row-item">
              <div v-if="row.item.total_fund_raised">
                ${{ row.item.original_total_fund_raised }} (${{
                  row.item.total_fund_raised
                }}
                )
              </div>
            </td>
            <td class="text-center row-item">
              <div style="font-weight: 700">
                {{ row.item.total_student }}/<span
                  style="color: rgba(29, 29, 29, 0.6)"
                  >{{ row.item.school_info_total_student }}</span
                >
              </div>
              <div style="color: #067605; font-weight: 500">
                {{ row.item.percentage_student }}%
              </div>
            </td>
            <td class="text-center row-item">
              <div style="font-weight: 700">
                {{ row.item.wizfit_video }}/<span
                  style="color: rgba(29, 29, 29, 0.6)"
                  >{{ row.item.total_wizfit_video }}</span
                >
              </div>
              <div style="color: #067605; font-weight: 500">
                {{ row.item.percentage_wizfit_video }}%
              </div>
            </td>
            <td class="text-center row-item">
              {{ row.item.total_email }}/{{ row.item.total_shares }}
            </td>
            <td class="text-center row-item">
              {{ row.item.total_phone }}/{{ row.item.sms_invites }}
            </td>
            <td class="text-center row-item">{{ row.item.social_shares }}</td>
            <td class="text-center">
              <div>{{ row.item.auth_account_count }}</div>
              <div v-if="row.item.payout_for">
                {{
                  row.item.payout_for.charAt(0).toUpperCase() +
                  row.item.payout_for.slice(1)
                }}
              </div>
            </td>
            <td class="text-center row-item">
              <v-chip
                @click="viewPublicDonationPageHandler(row.item)"
                class="actionColumn-btn"
                color="#F2F2F2"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="25"
                    >
                      mdi-open-in-new
                    </v-icon>
                  </template>
                  <span>Public Page</span>
                </v-tooltip>
              </v-chip>
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="viewCampaignHandler(row.item)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>Dashboard</span>
                </v-tooltip>
              </v-chip>
              <!-- <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="editCampaign(row.item, 'edit')"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-chip> -->

              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    class="actionColumn-btn"
                    color="#F2F2F2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="25" color="#6B6B6B"
                      >mdi-dots-horizontal</v-icon
                    >
                  </v-chip>
                </template>
                <v-list class="menu-list moreOptionsStyle py-0">
                  <v-list-item-group class="py-0">
                    <v-list-item
                      style="min-height: 40px"
                      @click="editCampaign(row.item, 'edit')"
                      v-if="
                        row.item.user_type == 'admin' ||
                        'Sales Representative' ||
                        'Event Support Staff'
                      "
                    >
                      <v-icon left size="18">mdi-pencil</v-icon>
                      <v-list-item-title style="font-size: 14px">
                        Edit Campaign
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      style="min-height: 40px; border-top: 1px solid #d2d2d2"
                      @click="deleteCampaign(row.item)"
                      v-if="
                        row.item.user_type == 'admin' ||
                        'Sales Representative' ||
                        'Event Support Staff'
                      "
                    >
                      <v-icon size="18" left>mdi-delete</v-icon>
                      <v-list-item-title style="font-size: 14px">
                        Delete Campaign
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      style="min-height: 40px; border-top: 1px solid #d2d2d2"
                      @click="getReport(row.item.id)"
                    >
                      <v-icon size="22" left>mdi-download</v-icon>
                      <v-list-item-title style="font-size: 14px">
                        Download Financial Report
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template
          v-slot:footer
          v-if="campaignDataTable.items[0] && $vuetify.breakpoint.smAndUp"
        >
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-6 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="justify-center justify-md-start d-flex px-5 tableHeader-text"
                >
                  <div class="alignSelf-center mr-3">Show</div>
                  <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                    <v-select
                      class=""
                      :items="itemsPerPage"
                      hide-details
                      v-model="
                        campaignDataTable[$API_KEYS.PAGE_INFO][
                          $API_KEYS.ITEMS_PER_PAGE
                        ]
                      "
                      dense
                    ></v-select>
                  </div>
                  <div class="alignSelf-center">
                    {{
                      campaignDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    }}
                    of
                    {{
                      campaignDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    }}
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="4" md="4">
                <div>
                  <v-pagination
                    v-model="
                      campaignDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    "
                    :length="
                      campaignDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    "
                    :total-visible="5"
                    color="#D30024"
                    class="pagination"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-card
      v-if="campaignDataTable.items[0] && $vuetify.breakpoint.xsOnly"
      flat
      class="footer-card"
    >
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="justify-center justify-md-start d-flex px-5 tableHeader-text"
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPage"
                  hide-details
                  v-model="
                    campaignDataTable[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{
                  campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{
                  campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <delete-modal @reload="getCampaignManagementData"></delete-modal>
    <notification-modal
      :notificationModal="notificationModal"
      v-if="notificationModal.flag"
    ></notification-modal>
  </div>
</template>
<script>
import {
  API_ADMIN_CAMPAIGN_MANAGEMENT_LIST,
  API_DONATION_REPORT_GENERATE,
  API_GET_FISCAL_YEAR_RANGE,
} from "@/constants/APIUrls";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
import { mapActions } from "vuex";
import {
  DATA,
  CAMPAIGN_LIST,
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
  CAMPAIGN_ID,
} from "@/constants/APIKeys";
import _ from "lodash";
import Axios from "@/api/BaseAxios";
import { ROUTER_URL } from "../../constants/urls";
import Helper from "@/helper";
export default {
  name: "CampaignManagement",
  components: {
    DeleteModal: () => import("./DeleteCampaign/DeleteCampaignModal"),
    NotificationModal: () => import("./NotificationModal/NotificationModal"),
    // EventTimeline: () => import("../PublicDonationPage/PublicDonationInfo/DonationInfoCard/EventTimeline1"),
  },
  data() {
    return {
      loading: false,
      yearList: [],
      yearFilter: "",
      showFlag: false,
      currentState: false,
      dataOptions: { sortBy: [], sortDesc: [] },
      campaignDataTable: {
        headers: [
          {
            width: "20%",
            sortable: true,
            text: "Campaign",
            align: "center",
            value: "campaign_status",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
          
            text: "District/School",
            align: "center",
            sortable: true,
            value: "name",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
         
            text: "Campaign Type",
            align: "center",
            sortable: true,
            value: "campaign_type",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
          
            sortable: true,
            text: "Donation-Donation w/Fees",
            align: "center",
            value: "total_fund_raised",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            width: "8%",
            sortable: true,
            text: "# Students",
            value: "total_students_registered",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
          },

          {
            sortable: true,
            text: "Wizfit Video",
            value: "total_videos_watched",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "10%",
            sortable: true,
            text: "# Email Shares",
            value: "total_shares",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "8%",
            sortable: true,
            text: "# SMS Shares",
            value: "sms_invites",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "10%",
            sortable: true,
            text: "# Social Shares",
            value: "social_shares",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "10%",
            sortable: false,
            text: "Stripe Setup",
            // value: "total_shares",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 50,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      campaignDataTable1: {
        headers: [
          {
            width: "30%",
            text: "Campaign",
            align: "center",
            value: "campaign_status",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            width: "30%",
            text: "District/School",
            align: "center",
            value: "campaign_status",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      itemsPerPage: [10, 15, 20, 30, 50, 100],
      status: "ONGOING",
      notificationModal: {
        flag: false,
      },
      adminAccess: localStorage.getItem("adminAccess"),
    };
  },
  created() {
    this.debounceGetCampaignManagementData = _.debounce(
      this.getCampaignManagementData,
      500
    );
  },
  computed: {},
  watch: {
    //Watcher for detecting change in PAGE_NUMBER
    "campaignDataTable.page_info.page_number": function () {
      this.getCampaignManagementData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "campaignDataTable.page_info.detail_count": function () {
      this.getCampaignManagementData();
    },
    //Watcher for detecting change in SEARCH
    "campaignDataTable.search": function () {
      this.debounceGetCampaignManagementData();
    },
    "dataOptions.sortBy": function () {
      console.log(this.dataOptions);
      if (this.dataOptions.sortBy.length !== 0) {
        this.campaignDataTable[SORTING_KEY] = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getCampaignManagementData();
      } else {
        this.campaignDataTable[SORTING_KEY] = "";
        this.getCampaignManagementData();
      }
    },
  },
  methods: {
    ...mapActions({
      // campaign Modal
      toggleAddEditViewCampaignModal: "campaignManagement/toggleModal",
      toggleModalDelete: "campaignManagement/toggleModalDelete",
    }),
    getCampaignManagementData() {
      const self = this;
      this.campaignDataTable.loading = true;
      const successHandler = (res) => {
        self.campaignDataTable.items = res[DATA][CAMPAIGN_LIST];
        if (self.campaignDataTable.items.length != 0) {
          self.progressStatus1 = self.campaignDataTable.items[0].progress;
          localStorage.setItem("progressValue1", this.progressStatus1);
        }
        self.campaignDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.campaignDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.campaignDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        self.campaignDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.campaignDataTable.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[SEARCH] = this.campaignDataTable[SEARCH];
      dataTableParams[ITEMS_PER_PAGE] =
        self.campaignDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      dataTableParams[PAGE_NUMBER] =
        self.campaignDataTable[PAGE_INFO][PAGE_NUMBER];
      dataTableParams[SORTING_KEY] = this.campaignDataTable[SORTING_KEY];
      if (this.showFlag == true) {
        dataTableParams.show_all = "show_all";
      }
      if (this.yearFilter) {
        dataTableParams.fiscal_year = this.yearFilter;
      }
      Axios.request_GET(
        API_ADMIN_CAMPAIGN_MANAGEMENT_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    ShowAll() {
      this.showFlag = true;
      this.currentState = true;
      this.getCampaignManagementData();
    },
    myEvents() {
      location.reload();
    },

    newCampaign() {
      this.toggleAddEditViewCampaignModal({
        type: "add",
        show: true,
      });

      // this.$root.$refs.adminLayout.route("campaign")
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.newCampaign.name,
        query: { type: "add" },
      });
    },
    editCampaign(item, action) {
      console.log("**********", item, action);
      this.toggleAddEditViewCampaignModal({
        show: true,
      });
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.newCampaign.name,
        query: { type: "edit", id: item.id },
      });
    },
    viewCampaignHandler(campaign) {
      const { id, district, school, campaign_type } = campaign;
      if (campaign_type === "district") {
        let routeData = this.$router.resolve({
          name: ROUTER_URL.districtPanel.children.dashboard.name,
          query: { [campaign_type]: district, [CAMPAIGN_ID]: id },
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          name: ROUTER_URL.schoolPanel.children.dashboard.name,
          query: { [campaign_type]: school, [CAMPAIGN_ID]: id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    viewCampaignHandlerHost(campaign) {
      const { id, district, school, campaign_type } = campaign;
      if (campaign_type === "district") {
        let routeData = this.$router.resolve({
          name: ROUTER_URL.districtPanel.children.dashboard.name,
          query: { [campaign_type]: district, [CAMPAIGN_ID]: id },
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          name: ROUTER_URL.schoolPanel.children.hosttodolist.name,
          query: { [campaign_type]: school, [CAMPAIGN_ID]: id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    viewPublicDonationPageHandler(item) {
      console.log("in scooolll");
      const { sub_domain } = item;
      console.log("in sub_domain", item.sub_domain);

      Helper.openPublicDonationPage(sub_domain);
    },
    deleteCampaign(item) {
      const { id } = item;
      console.log(id);
      this.toggleModalDelete({ Delete: true, id: id });
    },
    getReport(id) {
      this.notificationModal.flag = true;
      console.log(id);
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.notificationModal.flag = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.notificationModal.flag = false;
      };
      let formData = {};
      formData["campaign_id"] = id;
      Axios.request_GET_BLOB(
        API_DONATION_REPORT_GENERATE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
    getYearList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.yearList = data.year_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_GET_FISCAL_YEAR_RANGE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
  },
  mounted() {
    this.campaignDataTable.loading = false;
    /** Check if search param is present **/
    if (this.$route.params[SEARCH])
      this.campaignDataTable[SEARCH] = this.$route.params[SEARCH];
    this.getCampaignManagementData();
    this.getYearList();
  },
};
</script>
<style scoped>
.active-class {
  border-right: 4px solid #38227a;
  border-left: 4px solid #38227a;
  border-radius: 4px;
}
.card-datatable.v-card {
  margin-bottom: -2px;
  display: flex;
}
.text-capitalize.v-btn {
  position: absolute;
  right: 10px;
  letter-spacing: 0px;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.alignSelf-center {
  align-self: center;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.ONGOING {
  margin-top: 10px;
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.UPCOMING {
  margin-top: 10px;
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.COMPLETED {
  margin-top: 10px;
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.DRAFT {
  margin-top: 10px;
  border-radius: 10px;
  font-weight: 700;
}
.footer-card.v-card {
  max-height: 70px;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.theader {
  font-weight: 400;
  font-size: 13px;
  padding: 12px;
  text-align: center;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
  border-radius: 0px;
  min-width: 1500px;
}
.table-div {
  overflow-x: scroll;
}

.v-btn {
  font-family: Lato;
  font-weight: 600;
}
.v-input {
  font-family: Lato;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .v-input.search-input {
    display: flex;
    width: 240px;
    margin-left: 10px;
  }
  .v-icon.notranslate.mobile-menu {
    position: absolute;
    top: 25px;
  }
  .table-div {
    overflow: scroll;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
